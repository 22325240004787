import React, { useState } from "react";
import { Button, Box, TextField, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface CopyLinkCardProps {
    link: string;
    label: string;
}

const CopyLinkCard: React.FC<CopyLinkCardProps> = ({ link, label }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '8px', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
                {label}
            </Typography>
            <TextField value={link} fullWidth variant="outlined" sx={{ marginBottom: 2 }} inputProps={{ readOnly: true }} />
            <CopyToClipboard text={link} onCopy={handleCopy}>
                <Button variant="contained">
                    {copied ? "הועתק!" : "העתק"}
                </Button>
            </CopyToClipboard>
        </Box>
    );
};

export default CopyLinkCard;
