import { Grid } from "@mui/material";
import StatBlock from "../MyEventsPage/StatBlock";

interface AnalyticsSectionProps {
    activePracticipantsCount: number;
    allPracticipantsCount: number;
    uploadedImagesCount: number;
}

const AnalyticsSection: React.FC<AnalyticsSectionProps> = ({ activePracticipantsCount, allPracticipantsCount, uploadedImagesCount }) => {
    return (
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={4}>
                <StatBlock count={uploadedImagesCount} label="סך הכל תמונות שהועלו" />
            </Grid>
            <Grid item xs={4} >
                <StatBlock count={activePracticipantsCount} label="משתתפים פעילים" />
            </Grid>
            <Grid item xs={4} >
                <StatBlock count={allPracticipantsCount} label="סך הכל משתתפים" />
            </Grid>
        </Grid>
    );
}

export default AnalyticsSection;