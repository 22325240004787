import { useQuery } from "react-query";
import { getMyAlbumPageByCode } from "../../../api";
import MyAlbumPageStore from "../store";
import { useNavigate } from "react-router-dom";
import { IMyEventAlbumPage } from "../../../models/IMyEventAlbumPage";

export function useFetchAlbumByCode(eventId: string, phoneNumber: string, pinCode: string) {
    const navigate = useNavigate();

    const { data, error, isLoading, refetch } = useQuery<IMyEventAlbumPage>(
        ["myAlbumPageByCode"],
        () => getMyAlbumPageByCode(eventId, pinCode, phoneNumber),
        {
            enabled: !!pinCode, // Only fetch if pin code exists
            onError: () => {
                navigate(`/pin-code/${eventId}/${phoneNumber}`);
            },
            retry: false,
            onSuccess: (data) => {
                MyAlbumPageStore.setShowAlbumPage(true);
            }
        }
    );

    return { refetch, data, error, isLoading };
}