import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import defaultBackgroundImage from "../../assets/images/default_background_image.png";
import MyAlbumPageStore from "./store";
import { getEventPinCodeFromCookie } from "./utils";
import Loader from "../../components/Loader";
import AlbumPageUI, { whatsappLinkCache } from "./components/AlbumPageUI";
import { useFetchAlbumByCode } from "./hooks/useFetchAlbumByCode";
import ErrorPage from "../../components/404Page";
import { getSendMyImagesToWhatsappLink } from "../../api";

const MyAlbumPageByCode: React.FC<{}> = observer(() => {
    const { eventId, phoneNumber } = useParams();
    const navigate = useNavigate();

    if (!eventId || !phoneNumber) throw new Error("משהו השתבש אנא נסה שנית מאוחר יותר");

    const pinCode = getEventPinCodeFromCookie(eventId) || "";
    const { data, isLoading, error } = useFetchAlbumByCode(eventId, phoneNumber, pinCode);

    useEffect(() => {
        MyAlbumPageStore.setImages(data?.images.filter(Boolean) || []);
    }, [data]);

    useEffect(() => {
        if (!pinCode) {
            navigate(`/pin-code/${eventId}/${phoneNumber}`);
        }
    }, [pinCode, navigate, eventId, phoneNumber]);

    useEffect(() => {
        if (!MyAlbumPageStore.isFullScreen) {
            // Restore scroll position when exiting fullscreen mode
            window.scrollTo(0, MyAlbumPageStore.scrollPosition);
        }
    }, [MyAlbumPageStore.isFullScreen]);

    const handleShareAlbum = () => {
        const pinCode = getEventPinCodeFromCookie(eventId) || "";
        if (navigator.share) {
            navigator.share({
                title: data?.eventName || 'האירוע שלי',
                text: `
היי, אני רוצה לשתף אותך באלבום שלי מהאירוע: ${data?.eventName}
הקוד לצפייה הוא: ${pinCode}
${window.location.href}
                `,
            }).catch((error) => {
                console.error("Error sharing event album:", error);
            }).finally(() => {
                MyAlbumPageStore.setIsMoreActionsDialogOpen(false);
            });
        }
        MyAlbumPageStore.setIsMoreActionsDialogOpen(false);
    };

    const handleGlobalDownload = async () => {
        MyAlbumPageStore.handleGlobalDownload(eventId);
    };

    const handleOnSendToWhatsApp = async () => {
        // Check if the link is already cached
        if (whatsappLinkCache[eventId]) {
            // Open the cached link if available
            window.open(whatsappLinkCache[eventId], "_blank");
            return;
        }

        try {
            // Fetch the WhatsApp link and cache it
            const whatsappLink = await getSendMyImagesToWhatsappLink(eventId, { userPhoneNumber: phoneNumber });
            whatsappLinkCache[eventId] = whatsappLink;  // Cache the link

            window.open(whatsappLink, "_blank");
        } catch (err) {
            console.error(err);
        }
    };

    if (error) {
        return <ErrorPage />
    }
    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <AlbumPageUI
            backgroundImageUrl={data?.backgroundImageUrl || defaultBackgroundImage}
            mainImageUrl={data?.mainImageUrl || ""}
            eventName={data?.eventName || ""}
            eventDate={data?.eventDate || ""}
            images={MyAlbumPageStore.images}
            credits={data?.credits}
            onSendToWhatsApp={handleOnSendToWhatsApp}
            onGlobalDownload={handleGlobalDownload}
            onShareAlbum={handleShareAlbum}
        />
    );
});

export default MyAlbumPageByCode;
