import { Box, Button, CircularProgress, Divider, Fade, Modal, Typography } from "@mui/material";
import EventPageStore from "../store";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../../toastAlerts";
import { startEvent } from "../../../api";
import { IEvent } from "../../../models/IEvent";
import { observer } from "mobx-react";
import { Credits } from "../../../models/Credits";


const StartEventModal: React.FC<{}> = observer(() => {
    const handleClose = () => {
        EventPageStore.setStartEventModalOpen(false);
    }

    const handleConfirm = async () => {
        EventPageStore.setIsLaunchingEvent(true);
        const formattedPracticpentsNumbers = EventPageStore.practicipents.map((practicipant) => {
            return { ...practicipant, phoneNumber: practicipant.phoneNumber };
        });
        const credits: Credits | undefined = EventPageStore.magnetPhotographer || EventPageStore.stilsPhotographer ? [
            {
                label: "צלם מגנטים",
                value: EventPageStore.magnetPhotographer || "",
                link: EventPageStore.igLinkMagnetPhotographer
            },
            {
                label: "צלם סטילס",
                value: EventPageStore.stilsPhotographer,
                link: EventPageStore.igLinkStilsPhotographer
            }
        ] : undefined;


        const event: Partial<IEvent> = {
            name: EventPageStore.eventName,
            practicipants: formattedPracticpentsNumbers,
            eventType: EventPageStore.eventType,
            id: EventPageStore.eventId,
            mode: EventPageStore.eventMode,
            credits
        }

        try {
            const backgroundImage = EventPageStore.backgroundImage;
            const mainImage = EventPageStore.mainImage;

            const response = await startEvent(event, { backgroundImage, mainImage }) as any;
            if (response.status === 200) {
                successToast('האירוע שוגר בהצלחה !  🚀 ');
                EventPageStore.setIsEventActive(true);
            } else if (response.status === 400 && response.data.errorCode === 'TOO_MANY_PARTICIPANTS') {
                errorToast("אין אפשרות להתחיל אירוע עם יותר מ1000 מוזמנים");
            } else {
                errorToast('משהו השתבש בהתחלת האירוע...');
            }

        } catch (error: any) {
            errorToast('משהו השתבש בהתחלת האירוע...');
        } finally {
            EventPageStore.setIsLaunchingEvent(false);
            handleClose();
        }

    };

    const { isLoading: isUpdatePracticientsLoading, isError: isDeleteEventError, refetch: reUpdatePracticipents } = useQuery(["updatePraciticpents"], handleConfirm, {
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (isDeleteEventError)
            toast.error('משהו נכשל בהתחלת האירוע...', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
    }, [isDeleteEventError]);

    return (
        <Modal
            open={EventPageStore.startEventModalOpen}
            onClose={handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: '90vw', // maximum width of 90% of the viewport width
                    maxHeight: '90vh', // maximum height of 90% of the viewport height
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto' // add a scrollbar if the contents are too large
                }}
            >
                <Typography variant='h5' > ? האם ברצונך להתחיל אירוע</Typography>
                <Divider sx={{ width: "100%" }} />
                <Typography variant='h5' color="red"> ⚠️⚠️⚠️ זהירות </Typography>
                <Typography variant='h6' color='gray'> בלחיצה על "כן" האירוע יתחיל והודעות ישלחו למשתתפים </Typography>
                <Typography variant='h6' color='gray'> כמו כן יווצרו כל המשאבים הרלוונטים לאירוע </Typography>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: "20px" }}>
                    <Button variant='contained' color='primary' disabled={isUpdatePracticientsLoading} onClick={() => reUpdatePracticipents()}>
                        {
                            isUpdatePracticientsLoading ?
                                <CircularProgress color="inherit" size={24} />
                                : "כן"
                        }
                    </Button>
                    <Button variant='contained' color="error" onClick={handleClose}>לא</Button>
                </Box>
            </Box>
        </Modal>
    );
});

export default StartEventModal;