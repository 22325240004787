import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import defaultBackgroundImage from "../../assets/images/default_background_image.png";
import MyAlbumPageStore from "./store";
import Loader from "../../components/Loader";
import AlbumPageUI, { whatsappLinkCache } from "./components/AlbumPageUI";
import { useFetchAlbumBySharedLink } from "./hooks/useFetchAlbumBySharedLink";
import ErrorPage from "../../components/404Page";
import { getSendMyImagesToWhatsappLink } from "../../api";

const MyAlbumPageBySharedLink: React.FC<{}> = observer(() => {
    const { eventId, sharedLinkId } = useParams();

    if (!eventId || !sharedLinkId) throw new Error("משהו השתבש אנא נסה שנית מאוחר יותר");

    const { data, isLoading, error } = useFetchAlbumBySharedLink(eventId, sharedLinkId);

    useEffect(() => {
        MyAlbumPageStore.setImages(data?.images.filter(Boolean) || []);
    }, [data]);

    const handleShareAlbum = () => {
        if (navigator.share) {
            navigator.share({
                title: `האירוע של ${data?.eventName || ""}`,
                text: "היי, אני רוצה לשתף אותך באלבום מהאירוע",
                url: window.location.href,
            }).catch(console.error);
        };
    };

    const handleGlobalDownload = async () => {
        MyAlbumPageStore.handleGlobalDownload(eventId);
    };

    const handleOnSendToWhatsApp = async () => {
        // Check if the link is already cached
        if (whatsappLinkCache[eventId]) {
            // Open the cached link if available
            window.open(whatsappLinkCache[eventId], "_blank");
            return;
        }

        try {
            // Fetch the WhatsApp link and cache it
            const whatsappLink = await getSendMyImagesToWhatsappLink(eventId, { sharedEventLinkId: sharedLinkId });
            whatsappLinkCache[eventId] = whatsappLink;  // Cache the link

            window.open(whatsappLink, "_blank");
        } catch (err) {
            console.error(err);
        }
    };

    if (error) {
        return (
            <ErrorPage />
        );
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <AlbumPageUI
            backgroundImageUrl={data?.backgroundImageUrl || defaultBackgroundImage}
            mainImageUrl={data?.mainImageUrl || ""}
            eventName={data?.eventName || ""}
            eventDate={data?.eventDate || ""}
            images={MyAlbumPageStore.images}
            onSendToWhatsApp={handleOnSendToWhatsApp}
            onGlobalDownload={handleGlobalDownload}
            onShareAlbum={handleShareAlbum}
        />
    );
});

export default MyAlbumPageBySharedLink;
