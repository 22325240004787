import React from 'react';
import { Grid, Typography, Box, Avatar, Divider, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getSelfiePage } from '../../api';
import defaultBackgroundImage from "../../assets/images/default_background_image.png";
import MemoraLogo from "../../assets/images/memora-logo-transparent.png";
import SelfieButton from './SelfieButton';
import Loader from '../../components/Loader';
import sparkles from '../../assets/images/sparkles.png'; // Import the sparkles image
import { formatDate } from '../../utils/formatDate';

interface IGetSelfiePage {
    eventName: string;
    backgroundImageUrl: string;
    mainImageUrl: string;
    eventDate: string;
}

const GetSelfiePage: React.FC<{}> = () => {
    const { eventId } = useParams();

    if (!eventId) throw new Error("משהו השתבש אנא נסה שנית מאוחר יותר");

    const { data, error, isLoading } = useQuery<IGetSelfiePage>(
        ["getSelfiePage"],
        () => getSelfiePage(eventId),
        {
            enabled: true,
            retry: false,
        }
    );

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="h4" color="error">
                    משהו השתבש אנא נסה שנית מאוחר יותר...
                </Typography>
            </Box>
        );
    }

    if (isLoading || !data) {
        return <Loader />;
    }

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh" width="100%">
            {/* Main content section */}
            <Box flex={1}>
                <Grid container justifyContent="center" alignItems="center" width="100%">
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <img
                            src={data?.backgroundImageUrl || defaultBackgroundImage}
                            alt="Event"
                            style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                        />
                        {data?.mainImageUrl && (
                            <Avatar
                                alt="User"
                                src={data?.mainImageUrl}
                                style={{
                                    width: 80,
                                    height: 80,
                                    position: 'absolute',
                                    bottom: -40,
                                    right: 40,
                                    border: '3px solid white',
                                    backgroundColor: 'white',
                                    objectFit: 'contain',
                                    padding: 8,
                                    boxShadow: '0px 12px 24px 0px rgba(18, 64, 31, 0.08)',
                                }}
                            />
                        )}
                    </Grid>
                    <Box p={3} position="relative" mt={5}>
                        <Box
                            component="img"
                            src={sparkles}
                            alt="Sparkles"
                            sx={{
                                position: 'absolute',
                                top: 15,
                                right: 25,
                                width: 30,
                                height: 30,
                                zIndex: 1,
                            }}
                        />
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                textAlign="center"
                                sx={{
                                    position: 'relative',
                                    marginTop: '15px',
                                    marginBottom: '2px',
                                    fontWeight: '800',
                                    background: 'linear-gradient(90deg, #FF77C0 100%, #8745F3 66%, #70D4FF 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    backgroundClip: 'text',
                                    textFillColor: 'transparent',
                                    zIndex: 2, // Ensure text is above the sparkles
                                }}
                            >
                                {data?.eventName}
                            </Typography>
                            <Typography variant="h5" style={{ marginBottom: 8, fontWeight: "300" }} textAlign="center" >
                                {formatDate(data.eventDate)}
                            </Typography>
                            <Typography variant="body1" textAlign="center" style={{ marginBottom: 16 }}>
                                צלמו תמונת סלפי כדי שניצור עבורכם <br />
                                גלריה אישית מהאירוע
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                                <SelfieButton />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            {/* תקנון Section at the Bottom */}
            <Box sx={{ padding: 3, textAlign: "center" }}>
                <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    חשוב לנו לציין שתמונת הסלפי אינה נשמרת <br />
                    לחיצה על הכפתור מהווה הסכמה <Link href="https://bit.ly/3zGjtjN" underline="hover" target="_blank" sx={{ fontWeight: 'bold' }}>לתנאי השימוש והפרטיות</Link>
                </Typography>
                <Box
                    component="img"
                    src={MemoraLogo}
                    alt="Memora Logo"
                    sx={{
                        width: 180,
                        height: 80,
                        objectFit: 'contain',
                        mt: 2,
                    }}
                />
            </Box>
        </Box>
    );
};

export default GetSelfiePage;
