import React, { useState } from "react";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";

interface ActionButtonProps {
    label: string;
    onClick?: () => void;
    onDownload?: () => void;
    onSendToWhatsApp?: () => Promise<void>;
    Icon: React.ElementType;
    isLoading?: boolean;
    mode: "dropdown" | "direct"; // Add a mode prop to control behavior
}

const ActionButton: React.FC<ActionButtonProps> = ({ label, onClick, onDownload, onSendToWhatsApp, Icon, isLoading, mode }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isSendToWhatsAppLoading, setIsSendToWhatsAppLoading] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick();
        } else {
            // Open the dropdown menu in "dropdown" mode
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOnSendToWhatsApp = async () => {
        setIsSendToWhatsAppLoading(true);
        await onSendToWhatsApp!();
        setIsSendToWhatsAppLoading(false);
    }


    return (
        <>
            <Button
                variant="outlined"
                onClick={handleClick}
                disabled={isLoading}
                sx={{
                    position: "relative",
                    borderRadius: "16px",
                    height: "25px",
                    width: "165px",
                    padding: "6px 12px",
                    color: "black",
                    background: "#FFFFFF",
                    overflow: "hidden",
                    border: "1px solid transparent",
                    backgroundImage: "linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(157.4deg, #FF77C0, #8745F3, #70D4FF)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "padding-box, border-box",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                startIcon={!isLoading && <Icon />}
            >
                {isLoading ? (
                    <CircularProgress
                        size={20}
                        sx={{
                            color: "black",
                        }}
                    />
                ) : (
                    label
                )}
            </Button>

            {mode === "dropdown" && (
                <Menu
                    dir="rtl"
                    anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                        dir="rtl"
                        onClick={() => {
                            handleClose();
                            onDownload!();
                        }}>
                        הורדה
                    </MenuItem>
                    <MenuItem
                        dir="rtl"
                        disabled={isSendToWhatsAppLoading}
                        onClick={() => {
                            handleClose();
                            handleOnSendToWhatsApp!();
                        }}>
                        {isSendToWhatsAppLoading ? <CircularProgress size="12px" /> : "שלח לי את התמונות לוואטסאפ"}
                    </MenuItem>

                </Menu>
            )}
        </>
    );
};

export default ActionButton;
