import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import mingcuteCameraIcon from '../../assets/images/mingcute_camera-fill.png';
import MyAlbumPageStore from '../MyAlbumPage/store';
import { useNavigate, useParams } from 'react-router-dom';

const SelfieButton: React.FC = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [isPictureTaken, setIsPictureTaken] = useState(false);

    // Handle the image captured from the file input
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result as string;
                MyAlbumPageStore.setSelfieImage(file); // Store the File object in the store
                setIsPictureTaken(true);
                saveSelfieToLocalStorage(base64data); // Save base64 image to localStorage
                navigate(`/myEventAlbum/selfie/${eventId}`);
            };
            reader.readAsDataURL(file);
        }
    };

    // Save selfie image in localStorage
    const saveSelfieToLocalStorage = (imageData: string) => {
        localStorage.setItem('selfie', imageData); // Store base64 image in localStorage
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {/* Text above the button */}
            <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                    color: '#8745F3', // Text color
                    fontSize: '16px',
                    marginBottom: '8px', // Space between text and button
                }}
            >
                {isPictureTaken ? 'תמונה צולמה בהצלחה' : 'צלמו סלפי'}
            </Typography>

            {/* Input for camera capture */}
            {!isPictureTaken && (
                <Box>
                    <input
                        accept="image/*"
                        capture="user" // Use the front-facing camera
                        type="file"
                        onChange={handleFileChange} // Handle file input change
                        style={{
                            display: 'none',
                        }}
                        id="cameraInput"
                    />
                    <label htmlFor="cameraInput">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                backgroundColor: '#8745F3', // Purple background
                                cursor: 'pointer',
                                position: 'relative',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', // Shadow for depth
                                '&:hover': {
                                    backgroundColor: '#763fc1', // Slightly darker on hover
                                },
                            }}
                        >
                            <img
                                src={mingcuteCameraIcon}
                                alt="Camera Icon"
                                style={{
                                    width: '42px',
                                    height: '42px',
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '-6.5px',
                                    left: '-6.55px',
                                    width: '105px',
                                    height: '105px',
                                    borderRadius: '50%',
                                    border: '4px solid #8745F3', // Outer purple border
                                    zIndex: -1, // Behind the button
                                }}
                            />
                        </Box>
                    </label>
                </Box>
            )}
        </Box>
    );
};

export default SelfieButton;
