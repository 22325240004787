import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, CircularProgress, Divider } from "@mui/material"; // Import CircularProgress for the loader
import GradientIconButton from "./GradientDialogButton";
import { CheckBox, Download, IosShare } from "@mui/icons-material";
import MyAlbumPageStore from "../store";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { getEventPinCodeFromCookie } from "../utils";
import { downloadEventImagesAlbum, getImagesData } from "../../../api";

interface MoreActionsOverlayProps {
    imagesListRef: React.RefObject<HTMLDivElement>;
    selectButtonRef: React.RefObject<HTMLButtonElement>;
}

const MoreActionsOverlay: React.FC<MoreActionsOverlayProps> = observer(({ imagesListRef, selectButtonRef }) => {
    const { eventId } = useParams();
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState(false); // Track the loading state
    const [isSharing, setIsSharing] = useState(false); // Track the sharing state

    const handleCloseOverlay = () => {
        MyAlbumPageStore.setSelectedImages([]);
        MyAlbumPageStore.setIsMoreActionsDialogOpen(false);
        MyAlbumPageStore.setIsSelectImagesMode(false);
    };

    const handleShare = async () => {
        try {
            setIsSharing(true);
            const imagesData = await getImagesData(
                eventId!,
                MyAlbumPageStore.selectedImages.map((image) => image.key)
            );

            const files = imagesData
                .filter((image: { buffer: string | undefined }) => image.buffer !== undefined)
                .map((image: { buffer: string, key: string }) => {
                    const binary = atob(image.buffer);
                    const array = new Uint8Array(binary.length);
                    for (let i = 0; i < binary.length; i++) {
                        array[i] = binary.charCodeAt(i);
                    }
                    const blob = new Blob([array], { type: 'image/jpeg' });

                    return new File([blob], image.key, { type: 'image/jpeg' });
                });

            if (navigator.canShare && navigator.canShare({ files })) {
                await navigator.share({
                    title: "",
                    text: "",
                    files: files,
                });
            } else {
                console.log("Sharing not supported on this browser.");
            }
        } catch (error) {
            console.error("Error sharing images:", error);
        } finally {
            setIsSharing(false);
        }
    };


    const handleDownload = async () => {
        setLoading(true); // Start the loader
        try {
            if (MyAlbumPageStore.selectedImages.length === 1) {
                const menuImage = MyAlbumPageStore.selectedImages[0].downloadUrl;
                if (menuImage) {
                    const link = document.createElement('a');
                    link.href = menuImage;
                    link.download = 'image.jpg';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } else {
                const selectedImagesKeys = MyAlbumPageStore.selectedImages.map((image) => image.key);
                await downloadEventImagesAlbum(eventId!, selectedImagesKeys);
            }
        } catch (error) {
            console.error("Error downloading images:", error);
        } finally {
            setLoading(false);
        }
    };
    // Detect click outside to close the dialog and deselect images
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const dialogClicked = dialogRef.current?.contains(event.target as Node);
            const imagesListClicked = imagesListRef.current?.contains(event.target as Node);
            const selectButtonClicked = selectButtonRef.current?.contains(event.target as Node);

            if (!dialogClicked && !imagesListClicked && !selectButtonClicked && !MyAlbumPageStore.isFullScreen) {
                handleCloseOverlay();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef, imagesListRef, selectButtonRef]);

    const selectedImagesCount = MyAlbumPageStore.selectedImages.length;

    return (
        (selectedImagesCount > 0 && !MyAlbumPageStore.isFullScreen) ? <Box
            ref={dialogRef}
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#f8f8f8",
                padding: "10px 0",
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                zIndex: 1300,
                boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.15)", // Adjusted box shadow
            }}
        >
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Divider sx={{ width: "40px", borderBottomWidth: '2px' }} />
                <Typography variant="h6" fontWeight={500} sx={{ marginBottom: "3px" }}>פעולות</Typography>
                {selectedImagesCount > 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                        {selectedImagesCount === 1 ? "תמונה אחת נבחרה" : `${selectedImagesCount} תמונות נבחרו`}
                    </Typography>
                )}
                <Box display="flex" justifyContent="center" gap={2} width="100%">  {/* Added gap={2} */}
                    <GradientIconButton label="שיתוף" Icon={IosShare} onClick={handleShare} isLoading={isSharing} />
                    <GradientIconButton label="הורדה" Icon={Download} onClick={handleDownload} isLoading={loading} />
                </Box>
            </Box>
        </Box> : <></>
    );
});

export default MoreActionsOverlay;
