import React, { useState, useRef } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { AddPhotoAlternate, Close } from '@mui/icons-material';

interface ImagePickerProps {
    onImageSelected: (file: File | null) => void;
    name: string;
    label?: string;
    selectedImageUrl?: string;
}

const ImagePicker: React.FC<ImagePickerProps> = ({ onImageSelected, label, name, selectedImageUrl }) => {
    const [selectedImage, setSelectedImage] = useState<string | undefined>(selectedImageUrl);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedImage(URL.createObjectURL(file));
            onImageSelected(file);
        }
    };

    const handleRemoveImage = (event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent the click event from propagating to the label
        event.preventDefault();  // Prevent the default behavior of the label triggering the input
        setSelectedImage(undefined);
        onImageSelected(null); // Notify the parent component that the image has been removed

        // Reset the input value so the same file can be selected again
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <Box
            sx={{
                border: '2px dashed grey',
                borderRadius: '16px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px',
                width: '100%',
                textAlign: 'center',
                cursor: 'pointer',
                overflow: 'hidden', // Ensures the image doesn't overflow the container
                position: 'relative' // For positioning the remove button
            }}
        >
            <input
                accept="image/*"
                type="file"
                id={`${name}-image-picker`}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageChange}
            />
            <label htmlFor={`${name}-image-picker`} style={{ cursor: 'pointer', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {selectedImage ? (
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain'
                            }}
                        />
                        <IconButton
                            size="small"
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            }}
                            onClick={handleRemoveImage}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </Box>
                ) : (
                    <Box>
                        <AddPhotoAlternate fontSize="large" sx={{ color: 'grey' }} />
                        <Typography variant="body2" color="grey">
                            {label ? label : "לחץ לבחירת תמונה"}
                        </Typography>
                    </Box>
                )}
            </label>
        </Box>
    );
};

export default ImagePicker;
