import { Box } from "@mui/material";
import React from "react";
import MemoraLoader from "../assets/images/loader.gif";

const Loader: React.FC<{}> = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100vw", height: "100vh" }} // Full viewport width and height
        >
            <img src={MemoraLoader} style={{ width: "250px", height: "250px" }} alt="loader" />
        </Box>
    );
}

export default Loader;
