import React, { useEffect, useRef } from "react";
import { Box, Grid, Typography, Avatar, Divider, Button } from "@mui/material";
import { ArrowDownward, WhatsApp } from "@mui/icons-material";
import MyAlbumPageStore from "../store";
import ImagesList from "./ImagesList";
import ActionButton from "./ActionButton";
import FullscreenImageViewer from "./FullScreenImageViewer";
import MoreActionsDialog from "./MoreActionsDialog";
import { observer } from "mobx-react";
import NoImagesFound from "./NoImagesFound";
import BottomFooter from "./BottomFooter";
import { formatDate } from "../../../utils/formatDate";
import { Credits } from "../../../models/Credits";
import { AlbumImage } from "../../../models/AlbumImage";

interface AlbumPageUIProps {
    eventName: string;
    eventDate: string;
    mainImageUrl: string;
    backgroundImageUrl: string;
    images: AlbumImage[];
    credits?: Credits
    isLoading?: boolean;
    onGlobalDownload: () => void;
    onSendToWhatsApp?: () => Promise<void>;
    onShareAlbum: () => void;
}

export const whatsappLinkCache: Record<string, string> = {};


const AlbumPageUI: React.FC<AlbumPageUIProps> = observer(({
    eventName,
    eventDate,
    mainImageUrl,
    backgroundImageUrl,
    images,
    credits,
    isLoading,
    onGlobalDownload,
    onShareAlbum,
    onSendToWhatsApp,
}) => {
    const imagesListRef = useRef<HTMLDivElement | null>(null);
    const selectButtonRef = useRef<HTMLButtonElement | null>(null);

    const handleEnterFullScreen = (image: AlbumImage) => {
        MyAlbumPageStore.setExpandImage(image);
        MyAlbumPageStore.setScrollPosition(window.scrollY);
        MyAlbumPageStore.setIsFullScreen(true);
    };

    const handleSelectImages = () => {
        MyAlbumPageStore.setIsSelectImagesMode(!MyAlbumPageStore.isSelectImagesMode);
        MyAlbumPageStore.setSelectedImages([]);
    }

    useEffect(() => {
        if (!MyAlbumPageStore.isFullScreen) {
            window.scrollTo(0, MyAlbumPageStore.scrollPosition);
        }
    }, [MyAlbumPageStore.isFullScreen]);

    if (images.length === 0 && !isLoading) {
        return (
            <Grid
                container
                direction="column"
                sx={{ minHeight: "100vh", overflow: "hidden" }}
            >
                {/* Main Content Section */}
                <Grid item xs sx={{ flex: 1 }}>
                    <Box sx={{ position: "relative", width: "100%" }}>
                        {/* Background Image */}
                        <Box
                            component="img"
                            src={backgroundImageUrl}
                            alt="Event"
                            sx={{
                                width: "100%",
                                height: { xs: "250px", sm: "350px", md: "450px" },
                                objectFit: "cover",
                            }}
                        />
                        {/* Main Image Avatar */}
                        {mainImageUrl && (
                            <Avatar
                                alt="User"
                                src={mainImageUrl}
                                sx={{
                                    width: { xs: 80, sm: 100 },
                                    height: { xs: 80, sm: 100 },
                                    position: "absolute",
                                    bottom: -40,
                                    left: 40,
                                    border: "3px solid white",
                                    backgroundColor: "white",
                                    padding: 1,
                                }}
                            />
                        )}
                    </Box>

                    {/* Event Name and Date */}
                    <Box sx={{ textAlign: "center", mt: 10 }}>
                        <Typography
                            sx={{
                                marginBottom: "2px",
                                fontWeight: "800",
                                background: "linear-gradient(90deg, #FF77C0 100%, #8745F3 66%, #70D4FF 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                backgroundClip: "text",
                                textFillColor: "transparent",
                                fontSize: "34px",
                            }}
                        >
                            {eventName}
                        </Typography>
                        <Typography variant="h5" fontWeight="300" >
                            {formatDate(eventDate)}
                        </Typography>
                    </Box>

                    {/* Centered No Images Found Component */}
                    <Grid container justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                        <NoImagesFound />
                    </Grid>
                </Grid>

                {/* Footer Section */}
                <Grid item sx={{ width: "100%" }}>
                    <BottomFooter credits={credits || []} />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <FullscreenImageViewer />
            <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflow: "hidden" }}>
                {/* Main Content Container */}
                <Box sx={{ flex: 1, width: '100vw', overflowX: 'hidden', padding: 0, margin: 0 }}>
                    <Grid container sx={{ padding: 0, margin: 0, width: '100%' }}>
                        <Grid item xs={12} style={{ position: 'relative' }}>
                            <Box
                                component="img"
                                src={backgroundImageUrl}
                                alt="Event"
                                sx={{
                                    width: "100%",
                                    height: { xs: "250px", sm: "350px", md: "450px" },
                                    objectFit: "cover",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    zIndex: -1
                                }}
                            />
                            {mainImageUrl && (
                                <Avatar
                                    alt="User"
                                    src={mainImageUrl}
                                    sx={{
                                        width: { xs: 80, sm: 100 },
                                        height: { xs: 80, sm: 100 },
                                        position: "absolute",
                                        top: 190,
                                        left: 40,
                                        border: "3px solid white",
                                        backgroundColor: "white",
                                        padding: 1,
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: { xs: "270px", sm: "350px", md: "450px" } }}>
                            <Box p={{ xs: 2, sm: 3 }} sx={{ textAlign: "left" }}>
                                <Typography
                                    sx={{
                                        marginBottom: "2px",
                                        fontWeight: "800",
                                        background: "linear-gradient(90deg, #FF77C0 100%, #8745F3 66%, #70D4FF 100%)",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                        fontSize: "30px",
                                    }}
                                >
                                    {eventName}
                                </Typography>
                                <Typography variant="h5" fontWeight="300" sx={{ marginBottom: 2 }}>
                                    {formatDate(eventDate)}
                                </Typography>

                                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                                    היי, הכנו לך אלבום אישי עם כל התמונות שלך מהאירוע
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <ActionButton
                                            label="הורדת האלבום"
                                            Icon={ArrowDownward}
                                            mode="dropdown"
                                            onDownload={onGlobalDownload}
                                            onSendToWhatsApp={onSendToWhatsApp}
                                            isLoading={MyAlbumPageStore.isGlobalDownloadLoading} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ActionButton
                                            label="שיתוף האלבום"
                                            Icon={WhatsApp}
                                            mode="direct"
                                            onClick={onShareAlbum}
                                            isLoading={MyAlbumPageStore.isShareButtonLoading} />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ height: "5px", my: 3 }} />
                                <Box sx={{ textAlign: "right", mb: 2, mr: 2 }}>
                                    <Button
                                        ref={selectButtonRef}
                                        variant="outlined"
                                        size="small"
                                        color="inherit"
                                        sx={{ fontWeight: "bold", minWidth: "44px", minHeight: "44px" }}
                                        onClick={handleSelectImages}>
                                        {MyAlbumPageStore.isSelectImagesMode ? "ביטול בחירת תמונות" : "בחירת תמונות"}
                                    </Button>
                                </Box>
                                <Box ref={imagesListRef}>
                                    <ImagesList handleImageClick={handleEnterFullScreen} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <MoreActionsDialog imagesListRef={imagesListRef} selectButtonRef={selectButtonRef} />
                </Box>

                {/* Footer Section */}
                <BottomFooter credits={credits || []} />
            </Box>
        </>
    );
});

export default AlbumPageUI;
