import React from 'react';
import { Box, Typography, Avatar } from "@mui/material";
import weddingImage from '../../../assets/images/wedding.png';
import rectangle3 from '../../../assets/images/Rectangle3.png';
import rectangle4 from '../../../assets/images/Rectangle4.png';
import star1 from '../../../assets/images/Star1.png';
import union from '../../../assets/images/Union.png';
import vector from '../../../assets/images/Vector.png';
import vector2 from '../../../assets/images/Vector2.png';
import vector5 from '../../../assets/images/Vector5.png';
import memoraWhiteLogoOnly from '../../../assets/images/memora_white_logo_only.png';

interface MemoraCoverProps { }

const MemoraCover: React.FC<MemoraCoverProps> = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: { xs: '20px', lg: '82px 180px', md: '164px 360px', xl: '164px 360px' },
                gap: { xs: '32px', lg: '32px', md: '64px', xl: '64px' },
                width: { xs: '100%', lg: '348px', md: '696px', xl: '696px' },
                height: { xs: 'auto', lg: '498px', md: '996px', xl: '996px' },
                background: 'linear-gradient(157.4deg, rgba(255, 119, 192, 1) 7.03%, rgba(135, 69, 243, 1) 48.3%, rgba(112, 212, 255, 1) 95.95%)',
                borderRadius: { xs: '0', lg: '22px', md: '44px', xl: '44px' },
                position: 'relative',
                overflow: 'hidden',
                color: '#FFFFFF'
            }}
        >
            {/* Centered Image Box with Shadow */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { xs: '300px', lg: '217.5px', md: '435px', xl: '435px' },
                    height: { xs: '340px', lg: '245px', md: '490px', xl: '490px' },
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 6.63px 26.52px rgba(196, 206, 211, 0.4)',
                    borderRadius: '44.2px',
                    overflow: 'hidden',
                    zIndex: 1,
                }}
            >
                <Avatar
                    src={weddingImage}
                    alt="Event Cover"
                    variant="rounded"
                    sx={{
                        width: { xs: '250px', lg: '200px', md: '400px', xl: '400px' },
                        height: { xs: '250px', lg: '200px', md: '400px', xl: '400px' },
                        borderRadius: '33.15px',
                        marginBottom: { xs: "40px", lg: "20px", md: "40px", xl: "40px" }
                    }}
                />
            </Box>

            {/* Logo and Text */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { xs: '278px', lg: '278px', md: '556px', xl: '556px' },
                    gap: '12.3px',
                    zIndex: 4
                }}
            >
                {/* Logo with Text */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: { xs: '8px', lg: '4px', md: '8px', xl: '8px' },
                        mb: 1,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Rubik',
                            fontWeight: 700,
                            fontSize: { xs: '50px', lg: '35px', md: '70px', xl: '70px' },
                            lineHeight: '28px',
                            color: '#FFFFFF',
                        }}
                    >
                        memora
                    </Typography>
                    <Box
                        component="img"
                        src={memoraWhiteLogoOnly}
                        alt="Memora Logo Icon"
                        sx={{ width: { xs: '40px', lg: '30px', md: '60px', xl: '60px' }, height: { xs: '40px', lg: '30px', md: '60px', xl: '60px' } }}
                    />
                </Box>

                {/* Tagline */}
                <Typography
                    sx={{
                        fontFamily: 'Rubik',
                        fontWeight: 700,
                        fontSize: { xs: '20px', lg: '15px', md: '30.35px', xl: '30.35px' },
                        lineHeight: { xs: '48px', lg: '24px', md: '48px', xl: '48px' },
                        textAlign: 'center'
                    }}
                >
                    כי אושר אמיתי הוא אושר משותף!
                </Typography>
            </Box>

            {/* Decorative Images */}
            <Box component="img" src={star1} alt="Star" sx={{ position: 'absolute', width: { xs: '100px', lg: '86px', md: '172.57px', xl: '172.57px' }, height: { xs: '100px', lg: '86px', md: '172.57px', xl: '172.57px' }, top: '80.34px', right: '-10.25px', zIndex: 3 }} />
            <Box component="img" src={rectangle3} alt="Rectangle 3" sx={{ position: 'absolute', width: { xs: '50px', lg: '42.8px', md: '85.65px', xl: '85.65px' }, height: { xs: '30px', lg: '25px', md: '50.18px', xl: '50.18px' }, top: '150.19px', left: '326.62px', zIndex: 6 }} />
            <Box component="img" src={rectangle4} alt="Rectangle 4" sx={{ position: 'absolute', width: { xs: '35px', lg: '27.56px', md: '55.12px', xl: '55.12px' }, height: { xs: '40px', lg: '30.42px', md: '60.84px', xl: '60.84px' }, top: '50%', left: '30.87px', zIndex: 7 }} />
            <Box component="img" src={union} alt="Union" sx={{ position: 'absolute', width: { xs: '30px', lg: '25.99px', md: '51.98px', xl: '51.98px' }, height: { xs: '70px', lg: '58.97px', md: '117.93px', xl: '117.93px' }, right: '32px', top: 'calc(50% - 117.93px/2 - 40px)', zIndex: 8 }} />
            <Box component="img" src={vector} alt="Vector" sx={{ position: 'absolute', width: { xs: '70px', lg: '65.34px', md: '130.68px', xl: '130.68px' }, height: { xs: '40px', lg: '35.42px', md: '70.84px', xl: '70.84px' }, left: '40.56px', bottom: '78.41px', zIndex: 1 }} />
            <Box component="img" src={vector2} alt="Vector 2" sx={{ position: 'absolute', width: { xs: '90px', lg: '75.05px', md: '150.11px', xl: '150.11px' }, height: { xs: '150px', lg: '125.27px', md: '250.55px', xl: '250.55px' }, left: '0px', top: '120.36px', zIndex: 5 }} />
            <Box component="img" src={vector5} alt="Vector 5" sx={{ position: 'absolute', width: { xs: '70px', lg: '60.65px', md: '121.31px', xl: '121.31px' }, height: { xs: '60px', lg: '50.33px', md: '100.66px', xl: '100.66px' }, right: '70px', bottom: '150px', zIndex: 5 }} />
        </Box>
    );
};

export default MemoraCover;
